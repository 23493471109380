.wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}

*::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(136, 136, 136);
}
