.menuIcon {
  position: absolute;
  top: 20px;
  inset-inline-end: 20px;
  width: 40px;
  height: 40px;

  img {
    width: 40px;
    height: 40px;
  }
}

.wrapperMobile {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  width: 100vw !important;
  height: 100vh !important;

  .closeIcon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    inset-inline-end: 20px;
  }
}

.wrapper {
  background-image: url("../../assets/images/concrete.jpg");
  background-size: 700px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;

  .logo {
    width: 80%;
    margin-bottom: 50px;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 30px;

    li {
      cursor: pointer;
      position: relative;
      outline: none;
      padding: 20px 10px;
      width: 100%;
      border-radius: 10px;

      font: bold 16px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
      text-align: center;
      color: hsla(0, 0%, 20%, 1);
      text-shadow: hsla(0, 0%, 40%, 0.5) 0 -1px 0,
        hsla(0, 0%, 100%, 0.6) 0 2px 1px;

      background-color: hsl(0, 0%, 90%);
      box-shadow: inset hsla(0, 0%, 15%, 1) 0 0px 0px 4px,
        /* border */ inset hsla(0, 0%, 15%, 0.8) 0 -1px 5px 4px,
        /* soft SD */ inset hsla(0, 0%, 0%, 0.25) 0 -1px 0px 7px,
        /* bottom SD */ inset hsla(0, 0%, 100%, 0.7) 0 2px 1px 7px,
        /* top HL */ hsla(0, 0%, 0%, 0.15) 0 -5px 6px 4px,
        /* outer SD */ hsla(0, 0%, 100%, 0.5) 0 5px 6px 4px; /* outer HL */
      background-image: -webkit-repeating-linear-gradient(
          left,
          hsla(0, 0%, 100%, 0) 0%,
          hsla(0, 0%, 100%, 0) 6%,
          hsla(0, 0%, 100%, 0.1) 7.5%
        ),
        -webkit-repeating-linear-gradient(left, hsla(0, 0%, 0%, 0) 0%, hsla(
                0,
                0%,
                0%,
                0
              )
              4%, hsla(0, 0%, 0%, 0.03) 4.5%),
        -webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(
                0,
                0%,
                100%,
                0
              )
              1.2%, hsla(0, 0%, 100%, 0.15) 2.2%),
        linear-gradient(
          180deg,
          hsl(0, 0%, 78%) 0%,
          hsl(0, 0%, 90%) 47%,
          hsl(0, 0%, 78%) 53%,
          hsl(0, 0%, 70%) 100%
        );

      transition: color 0.2s;

      &:active {
        color: hsl(0, 100%, 40%);
        text-shadow: hsla(0, 100%, 20%, 0.3) 0 -1px 0,
          hsl(0, 100%, 85%) 0 2px 1px, hsla(0, 100%, 80%, 1) 0 0 5px,
          hsla(0, 100%, 50%, 0.6) 0 0 20px;
        box-shadow: inset hsla(0, 100%, 30%, 1) 0 0px 0px 4px,
          /* border */ inset hsla(0, 100%, 15%, 0.4) 0 -1px 5px 4px,
          /* soft SD */ inset hsla(0, 100%, 20%, 0.25) 0 -1px 0px 7px,
          /* bottom SD */ inset hsla(0, 100%, 100%, 0.7) 0 2px 1px 7px,
          /* top HL */ hsla(0, 100%, 75%, 0.8) 0 0px 3px 2px,
          /* outer SD */ hsla(0, 50%, 40%, 0.25) 0 -5px 6px 4px,
          /* outer SD */ hsla(0, 80%, 95%, 1) 0 5px 6px 4px; /* outer HL */
      }
    }
  }

  .selected {
    color: hsl(0, 100%, 40%);
    text-shadow: hsla(0, 100%, 20%, 0.3) 0 -1px 0, hsl(0, 100%, 85%) 0 2px 1px,
      hsla(0, 100%, 80%, 1) 0 0 5px, hsla(0, 100%, 50%, 0.6) 0 0 20px;
    box-shadow: inset hsla(0, 100%, 30%, 1) 0 0px 0px 4px,
      /* border */ inset hsla(0, 100%, 15%, 0.4) 0 -1px 5px 4px,
      /* soft SD */ inset hsla(0, 100%, 20%, 0.25) 0 -1px 0px 7px,
      /* bottom SD */ inset hsla(0, 100%, 100%, 0.7) 0 2px 1px 7px,
      /* top HL */ hsla(0, 100%, 75%, 0.8) 0 0px 3px 2px,
      /* outer SD */ hsla(0, 50%, 40%, 0.25) 0 -5px 6px 4px,
      /* outer SD */ hsla(0, 80%, 95%, 1) 0 5px 6px 4px; /* outer HL */
  }
}
